<template>
  <div class="elv-login-container">
    <div class="elv-login-title">
      <div v-if="!isSendCaptcha">
        <template v-if="loginState === 1">
          <p>{{ t('message.loginTitle') }} Elven</p>
          <p>
            {{ t('common.newToElven') }} <span @click="onJumpSignUp">{{ t('common.createAccount') }}</span>
          </p>
        </template>
        <p v-if="loginState === 2">{{ t('common.createAccount') }}</p>
      </div>
      <p v-else style="padding-bottom: 24px">{{ t('common.verifyEmail') }}</p>
    </div>

    <template v-if="!isEmailLogin">
      <!-- Google登录 -->
      <GoogleSign />

      <!-- Twitter登录 -->
      <!-- <TwitterSign /> -->

      <div class="elv-login-email-button" @click="onContinueWithEmail">
        <SvgIcon name="email-login" />
        {{ t('common.continueWithEmail') }}
      </div>

      <div class="elv-login-Terms-service">
        {{ t('message.agreedTermsOfService') }}
        <router-link to="/pact/privacyAgreement" class="elv-login-service-link">
          {{ t('common.userService') }} </router-link
        >.
      </div>
    </template>

    <EmailSign
      v-else
      ref="emailSignRef"
      :login-state="loginState"
      @changeLoginState="changeLoginState"
      @onContinueWithEmail="onContinueWithEmail"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import EmailSign from './components/EmailSign.vue'
import GoogleSign from './components/GoogleSign.vue'
// eslint-disable-next-line no-unused-vars
import TwitterSign from './components/TwitterSign.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const isEmailLogin = ref(true)
const loginState = ref(1)
const emailSignRef = ref()

const onContinueWithEmail = () => {
  isEmailLogin.value = !isEmailLogin.value
}

const changeLoginState = (val: number) => {
  loginState.value = val
}

const isShowService = computed(() => {
  return emailSignRef.value?.isShowService
})

const isSendCaptcha = computed(() => {
  return emailSignRef.value?.isSendCaptcha
})

const onJumpSignUp = () => {
  router.push({ name: 'signup', query: route.query })
}

onMounted(() => {
  if (route.name === 'login' && route.query?.state === '2') {
    loginState.value = 2
  }
})

defineExpose({ isShowService })
</script>

<style scoped lang="scss">
.elv-login-container {
  margin: 0;
  padding: 0;
  width: 100%;

  .elv-login-title {
    width: 100%;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #17181c;
    margin-bottom: 15px;

    p {
      text-align: center;
    }

    > div {
      p:nth-child(1) {
        margin-bottom: 24px;
      }

      p:nth-child(2) {
        font-size: 13px;

        font-weight: 500;
        line-height: 16px;
        padding-bottom: 12px;

        span {
          color: #1753eb;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .elv-login-email-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 315px;
    width: 100%;
    height: 44px;
    border: 1px solid #d0d4d9;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #1e2024;
    transition: background-color 0.218s, border-color 0.218s;

    svg {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }

    &:hover {
      box-shadow: none;
      border-color: #d2e3fc;
      outline: none;
      background: rgba(66, 133, 244, 0.04);
    }
  }

  .elv-login-Terms-service {
    width: 341px;
    height: 34px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #aaafb6;
    transform: scale(0.92);
    -moz-transform: scale(0.92);
    -moz-transform-origin: top left;
    margin-left: -14px;
    margin-top: 15px;

    .elv-login-service-link {
      color: #838d95;
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <div class="elv-signIn-container">
    <div class="elv-login-content">
      <img src="@/assets/img/sign-in-logo.png" alt="logo" class="elv-login-header-logo" />
      <div class="elv-login-left-content">
        <img src="@/assets/img/sign-in-logo.png" alt="logo" class="elv-login-left-logo" />
        <p class="elv-login-left-title">{{ t('message.loginInfoTitle') }}</p>
        <p class="elv-login-left-info">{{ t('message.loginInfoDesc') }}</p>
        <img src="@/assets/img/login-left.png" alt="" class="elv-login-left-img" />
      </div>
      <div class="elv-login-right-content">
        <SignIn ref="loginRef" class="elv-login-right-signin" />

        <div v-if="isShowService" class="elv-login-email-terms-service">
          {{ t('message.LoginAgreedTermsOfService') }}
          <a href="https://www.elven.com/terms-of-service" class="elv-login-service-link" target="_blank">{{
            t('common.userService')
          }}</a
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import SignIn from '@/components/Sign/SignIn.vue'

const { t } = useI18n()

const loginRef = ref()

const isShowService = computed(() => {
  return loginRef.value?.isShowService
})
</script>
<style scoped lang="scss">
.elv-signIn-container {
  width: 100vw;
  height: 100vh;
  background: #ebf4f9;
  position: relative;
}

.elv-login-content {
  padding: 16px;
  height: 582px;
  width: fit-content;
  border-radius: 10px;
  border: 1px solid #dde1e6;
  background: #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 小屏幕 768px-992px*/
  @media screen and (max-width: 992px) {
    // background-color: pink;
  }

  /* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
        把超小屏幕的区间降低1px*/

  /* 超小屏幕 768px屏幕以下*/
  @media screen and (max-width: 767px) {
    // width: calc(100vw - 69px);
    height: fit-content;
    position: absolute;

    .elv-login-header-logo {
      height: 54px;
      width: 154px;
      display: block !important;
      position: absolute;
      top: -77px;
      left: 50%;
      transform: translateX(-50%);
    }

    .elv-login-left-content {
      display: none;
    }

    .elv-login-right-content {
      padding: 0px;
      width: fit-content;

      .elv-login-container.elv-login-right-signin {
        position: unset;
        transform: unset;
        width: 290px;
        // min-width: calc(100vw - 101px);

        :deep(.el-form) {
          .el-input.elv-login-input {
            width: 100%;
            min-width: 290px;
            // min-width: calc(100vw - 101px);
          }

          .el-form-item {
            &.elv-login-form-captcha {
              .el-input {
                width: 40px;
              }
            }

            &.elv-form-item-operating {
              margin-bottom: 0px;
            }

            .elv-login-button__checkout {
              // min-width: calc(100vw - 101px);
              min-width: 290px;
            }
          }

          .elv-button.elv-login-continue,
          .el-button.elv-login-button--plain,
          .elv-button.elv-login-button,
          .elv-login-form-captcha__button,
          .elv-login-form-captcha__info {
            min-width: 290px;
            // min-width: calc(100vw - 101px);
          }
        }
      }

      .elv-login-email-terms-service {
        box-sizing: border-box;
        padding: 2px 4px;
        font-size: 11px;
        position: unset;
        width: 290px;
        margin-top: 38px;
      }
    }
  }

  .elv-login-header-logo {
    display: none;
  }
}

.elv-login-left-content {
  height: 100%;
  width: 460px;
  box-sizing: border-box;
  display: flex;
  position: relative;

  .elv-login-left-img {
    display: block;
    width: 460px;
    height: 550px;
    margin-right: 0px;
    border-radius: 5px 0px 0px 5px;
  }

  .elv-login-left-logo {
    width: 114px;
    height: 40px;
    display: block;
    position: absolute;
    left: 24px;
    top: 25px;
  }

  .elv-login-left-title {
    width: 422px;
    color: #0945de;
    font-size: 36px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    line-height: 48px;
    position: absolute;
    left: 24px;
    top: 105px;
  }

  .elv-login-left-info {
    width: 323px;
    color: #000;
    font-size: 15px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    line-height: 22px;
    position: absolute;
    left: 24px;
    top: 210px;
  }
}

.elv-login-right-content {
  width: 379px;
  padding: 0px 32px;
  box-sizing: border-box;
  position: relative;

  .elv-login-right-signin {
    // padding-top: 131.5px;
    position: absolute;
    width: 315px;
    top: 45%;
    left: 32px;
    transform: translateY(-45%);
  }

  .elv-login-email-terms-service {
    width: 100%;
    height: 36px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #838d95;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 17.64px;

    .elv-login-service-link {
      color: #838d95;
      text-decoration: underline;
    }
  }
}

.elv-sign-record-link {
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;

  &:hover {
    color: #1e2024;
  }
}
</style>
